/* 
* 通信用のファイル
*/

import { db } from "./firebase";
import { collection, doc, getDoc, addDoc, deleteDoc, updateDoc, serverTimestamp, query, where, getDocs, orderBy } from "firebase/firestore";

// todoをDBに追加する
export const addTodo = async (content, uid, dueDate, tag) => {
  try {
    await addDoc(collection(db, "todo"), {
      content: content,
      createAt: serverTimestamp(),
      isComplete: false,
      isDeleted: false,
      uid: uid,
      dueDate: dueDate ? dueDate : serverTimestamp(),
      tag: tag
    });
  } catch (error) {
    console.error("Error adding document: ", error);
  }
}

// ログインしているユーザのtodoを取得する
// 未完了タスクを取得する
export const initGet = async (uid, sort, deleteFlag=false, tagId = null) => { 
  let order;
  if(sort === 'createAt'){ // 作成日順
    order = ['createAt','desc'];
  }else if(sort === 'dueDate'){ // 期限順
    order = ['dueDate','asc'];
  }else if(sort === 'deleteAt'){ // 削除日順
    order = ['deleteAt','desc'];
  }
  let q = query(
    collection(db, "todo"), 
    where("uid", "==", uid),
    where("isDeleted", "==", deleteFlag),
    where("isComplete", "==", false),
    orderBy(order[0], order[1]),
  );
  // タグ条件が指定されている場合、where句を追加
  if (tagId) {
    q = query(q, where("tag.id", "==", tagId));
  }
  
  const querySnapshot = await getDocs(q);
  let todoItems = [];
  querySnapshot.forEach((doc) => {
    todoItems.push({
          id: doc.id,
          content: doc.data().content,
          isComplete: doc.data().isComplete,
          createAt: doc.data().createAt,
          updateAt: doc.data().updateAt ? doc.data().updateAt : doc.data().createAt,
          dueDate: doc.data().dueDate ? doc.data().dueDate : doc.data().createAt,
          tag: doc.data().tag ? doc.data().tag  :  {id: '999999', name:'未分類', color: '#eaeeef'} // タグ未設定の場合は「未分類」とする
        })
  });
  return todoItems;
}

// 完了済みタスクを取得する
export const getCompletedTodo = async(uid) => {
  const q = query(
    collection(db, "todo"), 
    where("uid", "==", uid),
    where("isComplete", "==", true),
    orderBy("completeAt", "desc")
  );
  const querySnapshot = await getDocs(q);
  let todoItems = [];
  querySnapshot.forEach((doc) => {
    todoItems.push({
          id: doc.id,
          content: doc.data().content,
          isComplete: doc.data().isComplete,
          createAt: doc.data().createAt,
          completeAt: doc.data().completeAt ? doc.data().completeAt : doc.data().createAt,
          updateAt: doc.data().updateAt ? doc.data().updateAt : doc.data().createAt ,
          dueDate: doc.data().dueDate ? doc.data().dueDate : doc.data().createAt 
        })
  });
  return todoItems;
}

// todoを削除する
export const todoDelete = async ( id = null , tagId = null ) => {
  try {
    if (tagId === null) {
      await deleteDoc(doc(db, "todo", id)); // 特定のタスクを削除
    } else {
      const q = query(
        collection(db, "todo"),
        where("tag.id", "==", tagId) // タグ ID が一致するタスクを取得
      );
      const querySnapshot = await getDocs(q);
      // タスクを1つずつ削除
      const deletePromises = querySnapshot.docs.map(docSnapshot =>
        deleteDoc(doc(db, "todo", docSnapshot.id))
      );
      // 全ての削除が完了するのを待つ
      await Promise.all(deletePromises);
    }
  } catch (error) {
    console.error("Error deleting todo:", error);
  }
}
// todoをゴミ箱に移動する（フラグを変更する）
export const todoTrash = async (id) => {
  const todo = doc(db, "todo", id);
  const docSnap = await getDoc(todo);
  if (docSnap.exists()) {
   await updateDoc(todo, {
      isDeleted: true,
      deleteAt: serverTimestamp()
    });
  }
}
// todoをゴミ箱から復元する
export const todoRestore = async (id) => {
  const todo = doc(db, "todo", id);
  const docSnap = await getDoc(todo);
  if (docSnap.exists()) {
   await updateDoc(todo, {
      isDeleted: false,
      deleteAt: null
    });
  }
}

// 未完了todoを完了のステータスに更新する
export const toggleComplete = async (id) => {
  const todo = doc(db, "todo", id);
  const docSnap = await getDoc(todo);
  if (docSnap.exists()) {
    const data = docSnap.data();
   await updateDoc(todo, {
      isComplete: data.isComplete ? false : true,
      completeAt: serverTimestamp(),
      isDeleted: false
    });
    console.log(data.isComplete);
  }
}

// 編集が完了したら、新しい内容でタスクを更新するための関数
export const saveEdit = async (id, newContent, newDueDate) => {
  const todo = doc(db, "todo", id);
  await updateDoc(todo, {
    id: id,
    content: newContent,
    updateAt: serverTimestamp(),
    dueDate: newDueDate
  });
};

// 全てのゴミ箱タスクを削除する
export const deleteAllDeletedTasks = async(uid) => {
  const todo = collection(db, 'todo');
  const q = query(todo, where('isDeleted', '==', true), where('uid', '==', uid)); // ユーザーごとのタスクを絞る
  const querySnapshot = await getDocs(q);
  // 削除処理
  querySnapshot.forEach(async (docSnapshot) => {
    await deleteDoc(doc(db, 'todo', docSnapshot.id));
  });
}

// 新規ユーザー作成時に、tags フィールドにデフォルトタグ（未分類）を登録
export const initializeUserTags = async (uid) => {
  const userRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userRef);
  if (!userDoc.exists() || !userDoc.data().tags) {
    await addDoc(userRef, {
      tags: [{ id: "default", name: "未分類", color: "#CCCCCC" }]
    }, { merge: true });
  }
};

// タグ一覧を取得
export const fetchTagList = async(uid) => {
  try {
    const userDocRef = doc(db, "users", uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return userDoc.data().tags;
    }
  } catch(error){
    console.log('タグ一覧取得エラー：'+ error);
  }
}
// タグを追加
export const addTag = async (uid,tagId,newTagName,color) => {
  try {
  const userRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userRef);
  const existingTags = userDoc.exists() ?  userDoc.data().tags || [] : [];
  if (existingTags.length >= 5) {
   return;
  }else{
    const updatedTags = [
      ...existingTags,
      { id: tagId, name: newTagName, color: color } 
    ];
    await updateDoc(userRef, { tags: updatedTags }, { merge: true }); 
  }
  } catch (error) {
    console.error("タグ追加中にエラーが発生しました: ", error);
  }
};

export const deleteTag = async (uid, tagId) => {
  try {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const tags = userDoc.data().tags || [];
      const updatedTags = tags.filter(tag => tag.id !== tagId);
      await updateDoc(userRef, { tags: updatedTags });
      console.log("Tag deleted successfully.");
    } 
  } catch (error) {
    console.error("Error deleting tag: ", error);
  }
};