import { DateDisplay } from './DateDisplay';

export const TrashTodoItem = (props) => {
    const { checkHandle, incompleteTodo, formattedCreatedAt, formattedUpdateAt, formattedDueDate,  deleteHandle, restoreHandle } = props;
    return (
        <li className='c-tasklist__item' >
            <div className="c-tasklist__head">
                <input
                type="button"
                onClick={() => checkHandle(incompleteTodo.id)}
                className="c-tasklist__doneBtn c-doneBtn"
                ></input>
                <p className="c-tasklist__name">{incompleteTodo.content}</p>
            </div>
            <div className="c-tasklist__body">
                <div>
                    { /* 更新日が存在する場合は更新日を表示 */ }
                    {formattedCreatedAt.year + formattedCreatedAt.month + formattedCreatedAt.date === formattedUpdateAt.year + formattedUpdateAt.month + formattedUpdateAt.date  ? 
                        (
                        <DateDisplay dateType="created" formattedDate={formattedCreatedAt} />
                        )
                        : 
                        (
                        <DateDisplay dateType="updated" formattedDate={formattedUpdateAt} />
                        )
                    }
                    <DateDisplay dateType="duedate" formattedDate={formattedDueDate}  />
                </div>

                <div className="c-tasklist__btnwrap">
                <button 
                    onClick={() => {
                        restoreHandle(incompleteTodo.id);
                    }}
                    className="c-inputBtn">復元</button>
                <button 
                    onClick={() => deleteHandle(incompleteTodo.id)}
                    className="c-inputBtn -done">削除</button>   
                </div>
            </div>
            </li>
    )
}