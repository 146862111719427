import React, { useState } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import * as Api from "../service/api";


export const TagManager = (props) => {
    const { currentUser, onClose, isTagManagerOpen, AiOutlineTags, tags, setTags, deleteTag, todoDelete,
      handleListClick
     } = props;

    const [isClosing, setIsClosing] = useState(false); // モーダルの開閉を管理
    const [newTagName, setNewTagName] = useState(''); // 追加するタグ名を管理
    const [selectedColor, setSelectedColor] = useState('#F3C4C6'); // タグカラーを管理
    const availableColors = ['#F3C4C6', '#FAE094', '#A0DAEA', '#A2D187', '#D9BB97'];

    // モーダルを閉じるアニメーション
    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
        }, 200);
    };


    const addTag = async(newTagName,selectedColor) => {
        if (tags.length >= 5) {
            alert('タグの登録は最大5つまでです');
        }else{
            if (newTagName) {
                const tagId = await Date.now();
                await Api.addTag(currentUser.currentUser.uid,tagId,newTagName,selectedColor);
                setTags([...tags, { id: tagId, name: newTagName, color: selectedColor }]);
                setNewTagName('');
            }else{
                alert('タグ名を入力してください');
            }
        }
    };

    const deleteTagWithTodos = async(tagId) => {
      await deleteTag(currentUser.currentUser.uid,tagId);
      const isConfirmed = window.confirm("対象のタグが付いたTODOも全て削除されます。タグを削除しますか？");
      if (!isConfirmed) {
        return; // ユーザーがキャンセルした場合、処理を終了
      }
      try{
        await deleteTag(currentUser.currentUser.uid,tagId);
        setTags(tags.filter(tag => tag.id !== tagId));
        await todoDelete(null,tagId);
        handleListClick();
      }catch(error){
        console.error("タグ削除中にエラーが発生しました: ", error);
        alert("タグの削除に失敗しました。もう一度お試しください。" + tagId);
      }

    };

    return (
        <div className={`p-tag-manager__header ${isClosing ? 'is-closing' : ''} ${isTagManagerOpen ? 'is-open' : ''}`}>
          <div className="modal-header">
            <h3 className='p-tag-manager__title'><AiOutlineTags />
            タグ管理</h3>
            <button onClick={handleClose} className="p-tag-manager__close-button"><IoCloseOutline />
            </button>
          </div>
          <div className="p-tag-manager__list">
            {tags.map(tag => (
              <div key={tag.id} className="p-tag-manager__item c-tag" style={{ backgroundColor: tag.color }}>
                <span className='p-tag-manager__item-name'>{tag.name}</span>
                <button onClick={() => deleteTagWithTodos(tag.id)} className='p-tag-manager__item-delete-button'><IoCloseOutline /></button>
              </div>
            ))}
          </div>
          <div className="p-tag-manager__form">
            <input
              type="text"
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
              placeholder="タグ名を入力"
              className='p-inputarea__input p-tag-manager__input'
            />
            <div className="p-tag-manager__color-picker">
              {availableColors.map(color => (
                <button
                  key={color}
                  style={{
                    backgroundColor: color,
                    border: selectedColor === color ? '2px solid black' : 'none',
                  }}
                  onClick={() => setSelectedColor(color)}
                />
              ))}
            </div>
            <button className="c-inputBtn -done" onClick={() => {addTag(newTagName,selectedColor)}} >追加</button>
          </div>
        </div>
    );
}