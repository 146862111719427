import { CompletedTodoItem } from './CompletedTodoItem';
import { Spinner } from "./Spinner";

export const CompleteTodos = (props) => {
  const { 
    todoCompletedItems, 
    fetchCompeletedTodos,
    toggleComplete,
    getFormattedDate,
    loadingTasks,
    fetchDeletedTodos 
  } = props;

  if (loadingTasks) {
    // データ取得中はスピナーを表示
    return <Spinner />;
  }
  
    return (
      <div className="p-doneArea">
        <p className="c-heading u-txtUpper">complate</p>
        <ul id="js-doneList" className="c-tasklist">
          {todoCompletedItems.map((completeTodo) => {
            const formattedCompleteAt = getFormattedDate(completeTodo.completeAt);
            const checkHandle = async (id) => {
              await toggleComplete(id);
              await fetchCompeletedTodos();
              await fetchDeletedTodos();  
            }

            return(
              <CompletedTodoItem
              key={completeTodo.id}
              incompleteTodo={completeTodo}
              formattedCompleteAt={formattedCompleteAt}
              checkHandle={checkHandle}
            />
            );
          })}
        </ul>
      </div>
    );
  };
  