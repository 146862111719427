import { DueDate } from './DueDate';

export const InputTodo = (props) => {
    const { todoText, onChangeTodoText, postTodo, disabled, setDueDate, dueDate, inputAreaRef, tags, selectedTag, setSelectedTag  } = props;

    const selectTag = (tag) => {
      setSelectedTag(tag);
    }

    return (
      <div ref={inputAreaRef} className="p-inputArea">
         <div className="p-inputArea__inner">
          <input
            className="p-inputarea__input"
            type="text"
            placeholder="TODOを入力"
            value={todoText}
            onChange={onChangeTodoText}
          />{' '}
          <DueDate
            dueDate={dueDate}
            setDueDate={setDueDate}
          />
          <div className="p-tag-manager__list">
            {tags.map(tag => (
              <button key={tag.id} onClick={()=>{selectTag(tag)}} 
              className={`p-tag-manager__item c-tag ${selectedTag.id === tag.id ? 'is-selected' : '' }`} 
              style={{ backgroundColor: tag.color }}>
                <span className='p-tag-manager__item-name'>{tag.name}</span>
              </button>
            ))}
          </div>

          <button
            type='button'
            disabled={disabled}
            onClick={postTodo}
            className="c-addBtn p-inputArea__addbtn"
          >追加</button> 
        </div>
      </div>
    );
  };
  