import { TodoItem } from './TodoItem';
import { Spinner } from "./Spinner";
import { TrashTodoItem } from './TrashTodoItem.js';
import { CategoryFilterList } from './CategoryFilterList.js';
import { NothingTodo } from './NothingTodo.js';

export const InCompleteTodos = (props) => {
    const { 
      todoItems, 
      todoDeletedItems, 
      todoDelete, 
      fetchCompeletedTodos, 
      fetchDeletedTodos, 
      toggleComplete,  
      getFormattedDate, 
      startEditing, 
      endEditing, 
      cancelEditing, 
      editingTodoId, 
      newContent, 
      setNewContent, 
      loadingTasks, 
      formattedDate, 
      setNewDueDate, 
      newDueDate, 
      handleListClick,
      handleTrashClick,
      handleDueDateView, 
      handleTagClick,
      viewMode, 
      todoTrash, 
      todoRestore, 
      handleScrollToInputArea, 
      handleDeleteAll,
      tags,
      sortTagId,
    } = props;
    if (loadingTasks) {
      // データ取得中はスピナーを表示
      return <Spinner />;
    }
    const noTasksMessage = (tags) => {
      let message;
      if(viewMode === 'uncategorized'){
        message = '未分類のTODOは存在しません';
      }else if(tags.find(tag => tag.id === sortTagId)?.name){
        message = tags.find(tag => tag.id === sortTagId)?.name + 'タグのTODOは存在しません';
      }else{
        message = '指定されたタグのTODOは存在しません'
      }
      return message;
    }

    const viewModeDisplay = viewMode === 'trash' ?
    (
      <>
        {todoDeletedItems.length === 0 ?
        <p className='p-desc'>現在、ゴミ箱にTODOはありません</p>
        :
        (<>
        <div className='u-txt__right'><button className='u-underline u-small' onClick={handleDeleteAll}>ゴミ箱を空にする</button></div>
        {todoDeletedItems.map((deletedItem) => {
          const formattedCreatedAt = getFormattedDate(deletedItem.createAt);
          const formattedUpdateAt = getFormattedDate(deletedItem.updateAt);
          const formattedDueDate = getFormattedDate(deletedItem.dueDate);
          const deleteHandle = async(id) => {
            const isConfirmed = window.confirm("本当に削除しますか？");
            if (isConfirmed) {
                await todoDelete(id);
            }
            await fetchDeletedTodos();            
          }
          const checkHandle = async (id) => {
            await toggleComplete(id);
            await fetchCompeletedTodos();
            await fetchDeletedTodos();  
          }
          const restoreHandle = async(id) => {
            await todoRestore(id);
            fetchDeletedTodos(); 
          }
          return(
            <TrashTodoItem
            key={deletedItem.id}
            incompleteTodo={deletedItem}
            formattedCreatedAt={formattedCreatedAt}
            formattedUpdateAt={formattedUpdateAt}
            formattedDueDate={formattedDueDate}
            formattedDate={formattedDate}
            deleteHandle={deleteHandle}
            checkHandle={checkHandle}
            restoreHandle={restoreHandle}
          />
          );
        })}
          <p className='p-desc -small'>※ゴミ箱内のTODOは30日で自動削除されます</p>
        </>
        )
        }
      </>
    ):( 
      <>
          {todoItems.length === 0 && viewMode === 'normal' ?
          (
            <NothingTodo
            handleScrollToInputArea={handleScrollToInputArea}
            />
          )
          : todoItems.length === 0 ? 

          <p className='p-desc'>{noTasksMessage(tags)}</p>
          :
          todoItems.map((incompleteTodo) => {
            const formattedCreatedAt = getFormattedDate(incompleteTodo.createAt);
            const formattedUpdateAt = getFormattedDate(incompleteTodo.updateAt);
            const formattedDueDate = getFormattedDate(incompleteTodo.dueDate);
            const deleteHandle = async(id) => {
              await todoTrash(id);
              fetchDeletedTodos(); 
              fetchCompeletedTodos();
            }
            const checkHandle = async (id) => {
              await toggleComplete(id);
              fetchCompeletedTodos();
            }
              return(
                <>
                <TodoItem
                  key={incompleteTodo.id}
                  incompleteTodo={incompleteTodo}
                  editingTodoId={editingTodoId}
                  newContent={newContent}
                  setNewContent={setNewContent}
                  startEditing={startEditing}
                  endEditing={endEditing}
                  cancelEditing={cancelEditing}
                  deleteHandle={deleteHandle}
                  checkHandle={checkHandle}
                  formattedCreatedAt={formattedCreatedAt}
                  formattedUpdateAt={formattedUpdateAt}
                  formattedDueDate={formattedDueDate}
                  formattedDate={formattedDate}
                  setNewDueDate={setNewDueDate}
                  newDueDate={newDueDate}
                />
                </>
              );
          })
          }
      </>
    );

    return (
      <div className="p-incompleteArea">
        <p className="c-heading u-txtUpper">TODO</p>
        <div className='p-CategoryFilterList__wrap'>
          <CategoryFilterList
          handleTrashClick={handleTrashClick}
          handleListClick={handleListClick}
          handleDueDateView={handleDueDateView}
          handleTagClick={handleTagClick}
          viewMode={viewMode}
          tags={tags}
          sortTagId={sortTagId}
          />
        </div>
        <ul id="js-incompleteList" className="c-tasklist">
        {viewModeDisplay}
        </ul>
      </div>
    );
  };
  