// 日付表示用の汎用コンポーネント
export const DateDisplay = ({ dateType, formattedDate }) => {
  // 日付が存在しない場合のチェック
  if (!formattedDate) {
    return null; // フォーマットされた日付がない場合は何も表示しない
  }

  // 日付の種類（作成日、更新日、完了日）に応じて表示テキストを変更
  const dateLabel = dateType === "created"
    ? (
<svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_124_152)">
<path fillRule="evenodd" clipRule="evenodd" d="M9.02344 1L11.3896 3.36618L4.83851 9.91728L2.47234 7.5511L9.02344 1ZM9.02344 2.41421L3.88655 7.5511L4.83851 8.50306L9.9754 3.36618L9.02344 2.41421Z" fill="#ccc"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.02344 1L11.3896 3.36618L9.46608 5.28971L7.0999 2.92354L9.02344 1ZM9.02344 2.41421L8.51411 2.92354L9.46608 3.8755L9.9754 3.36618L9.02344 2.41421Z" fill="#ccc"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.29893 10.1715L3.49852 7.64196L2.57379 7.20706L1.43428 9.7615C1.26865 10.1108 1.32794 10.4857 1.56671 10.5989C1.80548 10.7121 2.13331 10.5208 2.29893 10.1715Z" fill="#ccc"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.13545 9.80924L4.92144 8.87636L5.18701 9.80762L2.40045 10.7404C2.01578 10.8692 1.64463 10.7652 1.57145 10.508C1.49828 10.2509 1.75079 9.93805 2.13545 9.80924Z" fill="#ccc"/>
<path d="M2 9.5249L2.53586 9.00016L3.23552 9.71464L2.69965 10.2394L2 9.5249Z" fill="#ccc"/>
</g>
<defs>
<clipPath id="clip0_124_152">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg>


    )
    : dateType === "updated"
    ? (
        <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_123_101)">
          <mask id="mask0_123_101" maskUnits="userSpaceOnUse" x="1" y="7" width="10" height="4">
          <path d="M1 11H11V7.36364H1V11Z" fill="#ccc"/>
          </mask>
          <g mask="url(#mask0_123_101)">
          <path fillRule="evenodd" clipRule="evenodd" d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10ZM6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" fill="#ccc"/>
          </g>
          <mask id="mask1_123_101" maskUnits="userSpaceOnUse" x="1" y="1" width="10" height="4">
          <path d="M1 1H11V4.63636H1V1Z" fill="#ccc"/>
          </mask>
          <g mask="url(#mask1_123_101)">
          <path fillRule="evenodd" clipRule="evenodd" d="M6 2C8.20914 2 10 3.79086 10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2ZM6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1Z" fill="#ccc"/>
          </g>
          <path fillRule="evenodd" clipRule="evenodd" d="M3.40791 8.16838L1.27276 7.12971L1.71021 6.23047L3.84535 7.26914C4.14016 7.41255 4.28121 7.7301 4.16042 7.97842C4.03962 8.22674 3.70271 8.31179 3.40791 8.16838Z" fill="#ccc"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M1.83935 6.26923C2.08477 6.39582 2.16717 6.72439 2.02341 7.0031L0.994022 9.034C0.850255 9.31272 0.53476 9.43604 0.289343 9.30944C0.0439266 9.18285 -0.0384764 8.85429 0.10529 8.57557L1.13467 6.54467C1.27844 6.26596 1.59394 6.14264 1.83935 6.26923Z" fill="#ccc"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M10.4265 2.72687L10.1065 5.0796L11.0974 5.21436L11.4174 2.86163C11.4616 2.53679 11.2756 2.24328 11.0019 2.20607C10.7283 2.16886 10.4707 2.40203 10.4265 2.72687Z" fill="#ccc"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M11.137 5.08523C11.1678 4.8108 10.9401 4.56 10.6285 4.52505L8.36764 4.25517C8.05599 4.22022 7.77839 4.41434 7.74761 4.68876C7.71683 4.96318 7.94452 5.21398 8.25617 5.24894L10.517 5.51881C10.8287 5.55377 11.1063 5.35965 11.137 5.08523Z" fill="#ccc"/>
          </g>
          <defs>
          <clipPath id="clip0_123_101">
          <rect width="12" height="12" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      )
    : dateType === "completed"
    ? "完了："
    : dateType === "duedate"
    ? (
      <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C5.47405 1.5 4.96917 1.59023 4.5 1.75606C4.16332 1.87506 3.84503 2.03298 3.55054 2.22443C2.99547 2.58528 2.52494 3.06522 2.17517 3.62803C1.74718 4.31672 1.5 5.12949 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5ZM6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C5.36806 0.5 4.76102 0.606577 4.19586 0.802736C3.70803 0.972055 3.25141 1.20812 2.83692 1.5C2.26446 1.90313 1.77238 2.41272 1.38947 3C0.826921 3.86278 0.5 4.89321 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5Z" fill="#ccc"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.61541 4.57364L6.35192 6.19296L5.66992 6.92432L3.93341 5.30499C3.69365 5.08141 3.65195 4.73644 3.84028 4.53448C4.02861 4.33253 4.37565 4.35006 4.61541 4.57364Z" fill="#ccc"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.55753 6.84949C5.36004 6.65647 5.37765 6.31819 5.59685 6.09391L8.17672 3.45433C8.39593 3.23006 8.73372 3.20471 8.9312 3.39773C9.12868 3.59075 9.11108 3.92903 8.89187 4.15331L6.312 6.79288C6.0928 7.01716 5.75501 7.0425 5.55753 6.84949Z" fill="#ccc"/>
      </svg>
      )
    : "日付";


  return (
    <time
      className='c-tasklist__time'
      dateTime={`${formattedDate.year}-${formattedDate.month}-${formattedDate.date}`}
    >
      {dateLabel}{formattedDate.year}/{formattedDate.month}/{formattedDate.date}
    </time>
  );
};
