import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { registerLocale } from "react-datepicker";

//日本語化
registerLocale("ja", ja);

export const DueDate = (props) => {
    const { 
        setDueDate, 
        dueDate, 
        setNewDueDate, 
        newDueDate, 
        editingFlag } = props;

    const today = new Date();
    const dueDatePicker = editingFlag === true ?
    (
        <div className="c-tasklist__time">
            <span className="dueDate-edit">
            <DatePicker
            locale="ja"
            selected={newDueDate}
            onChange={(date) => setNewDueDate(date)}
            minDate={today}
            dateFormat="yyyy/MM/dd" 
            id="p-inputarea__date"  
            className="p-datepicker"
            popperPlacement="top"
            showIcon
            icon={
                <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_119_82)">
                <path fillRule="evenodd" clipRule="evenodd" d="M6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C5.47405 1.5 4.96917 1.59023 4.5 1.75606C4.16332 1.87506 3.84503 2.03298 3.55054 2.22443C2.99547 2.58528 2.52494 3.06522 2.17517 3.62803C1.74718 4.31672 1.5 5.12949 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5ZM6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C5.36806 0.5 4.76102 0.606577 4.19586 0.802736C3.70803 0.972055 3.25141 1.20812 2.83692 1.5C2.26446 1.90313 1.77238 2.41272 1.38947 3C0.826921 3.86278 0.5 4.89321 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5Z" fill="black"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M4.61541 4.57364L6.35192 6.19296L5.66992 6.92432L3.93341 5.30499C3.69365 5.08141 3.65195 4.73644 3.84028 4.53448C4.02861 4.33253 4.37565 4.35006 4.61541 4.57364Z" fill="black"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.55753 6.84949C5.36004 6.65647 5.37765 6.31819 5.59685 6.09391L8.17672 3.45433C8.39593 3.23006 8.73372 3.20471 8.9312 3.39773C9.12868 3.59075 9.11108 3.92903 8.89187 4.15331L6.312 6.79288C6.0928 7.01716 5.75501 7.0425 5.55753 6.84949Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_119_82">
                <rect width="12" height="12" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            }
            />
            </span>
        </div>
    )
    :
    (
        <>
            <DatePicker
            locale="ja"
            isClearable={dueDate ? true : false} 
            selected={dueDate}
            onChange={(date) => setDueDate(date)}
            placeholderText="締切を入力"
            minDate={today}
            dateFormat="yyyy/MM/dd" 
            id="p-inputarea__input"  
            className="p-datepicker"
            popperPlacement="top"
            />
            <small className="u-txt__right u-small u-txt__gray">※未入力の場合はTODO追加日が設定されます</small>
        </>
    );
    return dueDatePicker;
};