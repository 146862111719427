import React, { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import dig from "object-dig";
import { signInWithGoogle } from "../service/firebase";
import "../Header.css"; 

export const Header = () => {
    const currentUser = useContext(AuthContext);
    const isLoggedIn = !!dig(currentUser, "currentUser", "uid");
    // 認証
    const handleSignInWithGoogle = () => {
        signInWithGoogle(); // Googleサインインの処理
    };
    return (
        <>
          {isLoggedIn ? (
            // ログイン後
            <header className="p-header--logged-in">
              <div className="p-header-title">TODOを管理</div>
          </header>
          ) : (
            // ログイン前
            <header className="p-header p-header--guest">
              <img
                className="p-header__img"
                src="fv-img.png"
                alt="simple todo"
              />
              <div className="p-header__txts">
                <p className="p-header__copy u-txtUpper">
                  毎日のことだから
                  <br />
                  とにかくシンプルに。
                </p>
                <h1 className="p-header__h1">タスク管理アプリ SIMPLE TODO</h1>
              </div>
              <button
            className="c-loginBtn"
            onClick={handleSignInWithGoogle}>ログイン</button>
            </header>
          )}
        </>
      );
    };