import { AiOutlineTags } from "react-icons/ai";

export const CategoryFilterList = (props) => {
    const {
        handleTrashClick,
        handleListClick,
        handleDueDateView, 
        handleTagClick,
        viewMode,
        tags,
        sortTagId,
    } = props;
    
    return (
        <>
        <ul className='p-catlist'>
            <li>
                <button 
                    onClick={handleTrashClick} 
                    className={`c-catBtn -trash ${viewMode === 'trash' ? 'is-active' : ''}`}>
                    ゴミ箱
                </button>
            </li>            
            <li>
                <button 
                    onClick={handleListClick} 
                    className={`c-catBtn -sort ${viewMode === 'normal' ? 'is-active' : ''}`}>
                    一覧
                </button>
            </li>
            <li>
                <button 
                    onClick={handleDueDateView} 
                    className={`c-catBtn -sort ${viewMode === 'duedate' ? 'is-active' : ''}`}>
                    期限順
                </button>
            </li>
         {tags.map((tag)=>{
             return(
            <li>
                <button 
                    onClick={()=>{handleTagClick(tag.id)}} 
                    className={`c-catBtn -tag ${sortTagId === tag.id ? 'is-active' : ''}`}
                    style={{
                        background: tag.color,
                        color: '#fff'
                    }}>
                    <AiOutlineTags />{tag.name}
                </button>
             </li>
             )
         })}
        <li>
            <button 
                onClick={()=>{handleTagClick('999999')}} 
                className={`c-catBtn -tag ${sortTagId === '999999' ? 'is-active' : ''}`}>
                <AiOutlineTags />未分類
            </button>
        </li>
     </ul>
     </>
    );
}