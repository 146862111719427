export const NothingTodo = (props) => {
    const {handleScrollToInputArea} = props;
    return(
        <div className='p-nothing u-center'>
            <img
            className="p-nothing__img"
            src="nothingTodo.png"
            alt="nothingTodo"
            />
            <p className='p-nothing__ttl'>現在、TODOはありません</p>
            <p className='p-nothing__desc'>いつもお疲れさまです。< br />やることができたら忘れずにTODOを登録しましょう！</p>
            <button className="c-addBtn p-nothing__btn" onClick={handleScrollToInputArea}>TODOを登録する</button>
        </div>
    )
}