import { DateDisplay } from './DateDisplay';
import { DueDate } from './DueDate';

export const TodoItem = (props) => {
    const { startEditing, endEditing, cancelEditing, editingTodoId, newContent, setNewContent, checkHandle, incompleteTodo, formattedDueDate,  deleteHandle, formattedDate, newDueDate, setNewDueDate } = props;
    // 期限切れタスクの場合、クラスを追加する
    const checkOverdue = formattedDate > formattedDueDate.year + "/" + formattedDueDate.month + "/" + formattedDueDate.date
        ? "c-tasklist__item is-overdue"
        : "c-tasklist__item";

    // カラーをrgbaにして渡す
    const hexToRgb = (hex) => {
        // Remove the hash symbol if present
        const sanitizedHex = hex.replace('#', '');
        const bigint = parseInt(sanitizedHex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
        };

    return (
        <li 
        className={checkOverdue}>
            <div className="c-tasklist__head">
                <input
                type="button"
                onClick={() => checkHandle(incompleteTodo.id)}
                className="c-tasklist__doneBtn c-doneBtn"
                style={{
                    borderColor: `rgba(${hexToRgb(incompleteTodo.tag.color)}, 0.6)`,
                    }}
                ></input>
                {/* 編集モードかどうかで表示を切り替える */}
                {editingTodoId === incompleteTodo.id ? (
                <input
                    type="text"
                    value={newContent}
                    onChange={(e) => setNewContent(e.target.value)}
                    className="p-inputarea__input"
                />
                ) : (
                    <>
                    <p className="c-tasklist__name">{incompleteTodo.content}</p>
                    </>
                )}
                <span className="c-tasklist__tag" style={{ borderColor: incompleteTodo.tag.color }} ></span>
            </div>
                       <div className="c-tasklist__body">
                <div>
                    { /* 編集モードかどうかで表示を切り替える */ }
                    {editingTodoId === incompleteTodo.id ? 
                    (
                        <DueDate
                        editingFlag={true}
                        newDueDate={newDueDate}
                        setNewDueDate={setNewDueDate}
                        />
                        )
                    :
                    <DateDisplay dateType="duedate" formattedDate={formattedDueDate}  />
                    }
                </div>

                <div className="c-tasklist__btnwrap">
                {/* 編集モードかどうかで表示を切り替える */}
                {editingTodoId === incompleteTodo.id ? 
                    (
                        <>
                        <button 
                            onClick={() => {
                                endEditing(incompleteTodo);
                            }}
                            className="c-inputBtn -done">保存</button>
                            <button 
                            onClick={() => {
                                cancelEditing();
                            }}
                            className="c-inputBtn">閉じる</button>
                        </>
                    ) : (
                        <>
                        <button 
                        onClick={() => {
                            startEditing(incompleteTodo);
                        }}
                        className="c-inputBtn -done">編集</button>
                        <button
                            type="button"
                            onClick={() => {
                            deleteHandle(incompleteTodo.id);
                            }}
                            className="c-icon-trash"
                        ></button>
                        </>
                    )
                }

                </div>
            </div>
            </li>
    )
}