import React, { useState } from "react";
import { FaCirclePlus } from "react-icons/fa6";
import "../BottomMenu.css"; 
import { signOutWithGoogle } from "../service/firebase";
import { IoSettingsOutline, IoLogOutOutline } from "react-icons/io5";
import { AiOutlineTags } from "react-icons/ai";
import { TagManager } from "./TagManager";

const BottomMenu = (props) => {
    const {handleScrollToInputArea,
      currentUser,
      isLoggedIn,
      loadingTasks,
      setTags,
      tags,
      todoDelete,
      deleteTag,
      handleListClick
    } = props;
    const [isTagManagerOpen, setTagManagerOpen] = useState(false);


    // ログアウト処理
    const handleSignOut = () => {
        signOutWithGoogle();
    };

    // タグ管理
    const toggleTagManager = () => {
      setTagManagerOpen(!isTagManagerOpen);
    };

    if(loadingTasks){ return;}

  return (
    <>
   { isLoggedIn ?
   (<nav className="bottom-menu">
      <ul className="menu-list">
        <li className="menu-item">
          <button className="menu-button -add" onClick={handleScrollToInputArea}>
            <FaCirclePlus />
          </button>
        </li>
        <li>
            <button onClick={toggleTagManager} className="menu-button">
            <AiOutlineTags />
            </button>
            {/* タグ管理モーダル */}
            {isTagManagerOpen && <TagManager 
            currentUser={currentUser}
            onClose={toggleTagManager}
            isTagManagerOpen={isTagManagerOpen}
            AiOutlineTags={AiOutlineTags}
            setTags={setTags}
            tags={tags}
            todoDelete={todoDelete}
            deleteTag={deleteTag}
            handleListClick={handleListClick} />}
        </li>
        <li>
            <button className="menu-button">
                <IoSettingsOutline />
            </button>
        </li>
        <li>
            <button className="menu-button" onClick={handleSignOut}>
                <IoLogOutOutline />
            </button>
        </li>
      </ul>
    </nav>):''
    }
    </>
  );
};

export default BottomMenu;
