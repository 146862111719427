import React, { useRef } from "react";
import './App.css';
import './service/firebase';
import { Header } from './components/Header';
import { AuthProvider } from './providers/AuthProvider';
import { Footer } from './components/Footer';
import { Dashboard } from './components/Dashboard';
import ViewportHandler from "./components/ViewportHandler";

const App = () => {
    // スムーススクロール
    const inputAreaRef = useRef(null);
    const handleScrollToInputArea = () => {
      if (inputAreaRef.current) {
        const elementPosition = inputAreaRef.current.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - (window.innerHeight / 2) + (inputAreaRef.current.offsetHeight / 2);
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    };

  return (
    <AuthProvider>
      <ViewportHandler />
      <div className={"l-rootContainer"}>
      <Header />
        <Dashboard
        handleScrollToInputArea={handleScrollToInputArea}
        inputAreaRef={inputAreaRef} />
      <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
