import React, { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "../providers/AuthProvider";
import dig from "object-dig";
import { InputTodo } from './InputTodo';
import { InCompleteTodos } from './InCompleteTodos';
import { CompleteTodos } from './CompleteTodos';
import * as Api from "../service/api";
import { Spinner } from "./Spinner";
import { Timestamp } from "firebase/firestore";
import BottomMenu from "./BottomMenu";

export const Dashboard = (props) => {
  const {handleScrollToInputArea,inputAreaRef} = props;

    /*
    * ステートの宣言
    */
    const [todoText, setTodoText] = useState('');
    const [todoItems, setTodoItems] = useState([]);
    const [localTodoItem, setLocalTodoItem] = useState(true); // ローカルでタスクが追加されたことを検知する
    const [todoCompletedItems, setTodoCompletedItems] = useState([]);
    const [todoDeletedItems, setTodoDeletedItems] = useState([]);
    const [editingTodoId, setEditingTodoId] = useState(null); // 編集モードのタスクID
    const [newContent, setNewContent] = useState(""); // 編集中のタスク内容
    const [newDueDate, setNewDueDate] = useState(""); // 編集中の期限
    const [loadingTasks, setLoadingTasks] = useState(true); // データ取得ローディングを管理する
    const [dueDate, setDueDate] = useState(null);
    const [viewMode, setViewMode] = useState('normal'); // normal trash duedate tags
    const [sortCriteria, setSortCriteria] = useState("createAt"); // DBからデータを取得する際の並び順
    const [sortTagId, setSortTagId] = useState(''); // ソートするタグのidを管理
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState({id: '999999', name:'未分類', color: '#eaeeef'}); // inputエリアで選択されたタグ情報


    /*
    * コンテキストから現在のユーザーを取得
    */
    const currentUser = useContext(AuthContext);
    const isLoggedIn = !!dig(currentUser, "currentUser", "uid");

    /*
    * 入力欄（タスク）の値を取得
    */
    const onChangeTodoText = (e) => setTodoText(e.target.value);

    /*
    * 今日の日付を取得してフォーマットする（yy/mm/dd）
    */
    const date = new Date();
    const formattedDate = date.toLocaleDateString("ja-JP", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    });


    /*
    * タスク追加（入力がない場合は「無題」として追加）
    */
    const postTodo = async () => {
      const textToAdd = todoText.trim() === '' ? '無題' : todoText;
      // 新しいタスクのオブジェクトを作成
      const newTask = {
        content: textToAdd,
        dueDate: dueDate ? Timestamp.fromDate(dueDate) : Timestamp.fromDate(new Date()), 
        id: Date.now(),
        createAt: Timestamp.fromDate(new Date()),
        updateAt: Timestamp.fromDate(new Date()),
        completed: false,
        tag: selectedTag
      };
      setTodoItems((prev) => [newTask, ...prev]);
      await Api.addTodo(textToAdd,currentUser.currentUser.uid,dueDate,selectedTag);
      await setTodoText('');
      await setDueDate('');
      setSortCriteria('createAt');
      setViewMode('normal');
      setLocalTodoItem(localTodoItem ? false : true);
      setSelectedTag({id: '999999', name:'未分類', color: '#eaeeef'});
      setSortTagId();
    }

    /*
    * タスクの取得（初回）
    */
    const initialFetchTodos = useCallback(async () => {
      if (dig(currentUser, 'currentUser', 'uid')) { 
        // 初回はログイン情報が取得できていないため、その場合はDBに情報をとりに行かない条件分岐を実装
        setLoadingTasks(true);
        const getTodoItems = await Api.initGet(currentUser.currentUser.uid,'createAt',false);
        await setTodoItems(getTodoItems);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoadingTasks(false);
      }
    }, [currentUser]);
    useEffect(() => {
      initialFetchTodos();
    }, [initialFetchTodos]);

    // 完了タスクver
    const initialFetchCompletedTodos = useCallback(async () => {
      if (dig(currentUser, 'currentUser', 'uid')) { 
        const getTodoCompletedItems = await Api.getCompletedTodo(currentUser.currentUser.uid);
        await setTodoCompletedItems(getTodoCompletedItems);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }, [currentUser]);
    useEffect(() => {
      initialFetchCompletedTodos();
    }, [initialFetchCompletedTodos]);

    /*
    * タスクの取得
    */
    // 未完了タスクを取得
    useEffect(() => {
      const fetchTodos = async(sortCriteria,sortTagId) => {
        if (!dig(currentUser, 'currentUser', 'uid')) return; // ログイン前は何もしない
        const getTodoItems = await Api.initGet(currentUser.currentUser.uid,sortCriteria,false,sortTagId);
        await setTodoItems(getTodoItems);
      }
      fetchTodos(sortCriteria,sortTagId);
    }, [sortTagId,sortCriteria,todoCompletedItems,todoDeletedItems,localTodoItem,currentUser]);    

    // 完了タスクを取得
    const fetchCompeletedTodos = async() => {
      const getTodoItems = await Api.getCompletedTodo(currentUser.currentUser.uid);
      await setTodoCompletedItems(getTodoItems);
    }
    // ごみ箱タスクを取得
    const fetchDeletedTodos = async() => {
      const getTodoItems = await Api.initGet(currentUser.currentUser.uid,'deleteAt',true);
      await setTodoDeletedItems(getTodoItems);
    }


    /*
    * タスクを編集する
    */
    const startEditing = (incompleteTodo) => { // 編集ボタンをクリックしたら動く関数
      setEditingTodoId(incompleteTodo.id);
      setNewContent(incompleteTodo.content);
      const formattedDueDate = getFormattedDate(incompleteTodo.dueDate);
      setNewDueDate(formattedDueDate.year + '/' + formattedDueDate.month + '/' + formattedDueDate.date);
    };
    // 編集が完了したら、新しい内容でタスクを更新するための関数
    const endEditing = async (incompleteTodo) => { 
      const editDuedate = typeof newDueDate === "string" // 初期値が文字列（yyyy/mm/dd形式）の場合はDateに変換
      ? convertToDate(newDueDate)
      : newDueDate;
      await Api.saveEdit(incompleteTodo.id,newContent,editDuedate);
      
      setTodoItems((prevItems) =>
        prevItems.map((item) => {
          if (item.id === incompleteTodo.id) {
            console.log(editDuedate)
            console.log(item.dueDate)
            return {
              ...item,
              content: newContent || item.content, // updatedTodo.contentがundefinedの場合は元の値
              dueDate: Timestamp.fromDate(editDuedate) || item.dueDate, // 同上
            };
          }
          return item;
        })
      );
      
      setEditingTodoId(null);
    };
    // 編集をキャンセルする関数
    const cancelEditing = useCallback(() => {
      setEditingTodoId(null);
    },[]);
    // ボタンをクリックしたら、編集モードを終了させる
    useEffect(() => {
      const handleClickOutside = (e) => {
          // クリックされた要素がボタンであり、かつ以下の条件でない場合にcancelEditingを呼び出す
          const isButtonOrInput = ['button', 'input'].includes(e.target.tagName.toLowerCase());
          const isExcludedButton =
              e.target.closest('.c-tasklist__item'); // .c-tasklist__item内のボタンは対象外にする
          if (isButtonOrInput && !isExcludedButton) {
              cancelEditing();
          }
      };
      // クリックイベントをリスン
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [cancelEditing]);

    /*
    * 一覧の切り替え
    */
    const handleViewChange = async (mode, criteria = 'createAt', tagId = '', fetchTrash = false) => {
      setSortCriteria(criteria);
      setViewMode(mode);
      setSortTagId(tagId);
    
      if (fetchTrash) {
        await fetchDeletedTodos();
      }
    };
    // ゴミ箱ボタンをクリックしたときの処理
    const handleTrashClick = async () => {
      await handleViewChange('trash', 'createAt', '', true);
    };

    // 一覧ボタンをクリックしたときの処理
    const handleListClick = () => {
      handleViewChange('normal');
    };

    // 期限一覧ボタンをクリックしたときの処理
    const handleDueDateView = () => {
      handleViewChange('duedate', 'dueDate');
    };

    // タグボタンをクリックした時の処理
    const handleTagClick = (tagId) => {
      if (tagId === '999999') { // 未分類タグの場合
        handleViewChange('uncategorized', 'dueDate', '999999');
      } else { // それ以外のタグの場合
        handleViewChange('tags', 'dueDate', tagId);
      }
    };    
    
    // ゴミ箱のタスクを全て削除する
    const handleDeleteAll = async () => {
      const isConfirmed = window.confirm("ゴミ箱の内容を全て削除してよろしいですか？");
      if (isConfirmed) {
        setLoadingTasks(true);
        await Api.deleteAllDeletedTasks(currentUser.currentUser.uid);  // Firebaseの関数を呼び出し
        await fetchDeletedTodos();
        setLoadingTasks(false);
      }
    };

    /*
    * タグ管理
    */
    useEffect(() => {
      const fetchTagList = async() => {
        const getTagList = await Api.fetchTagList(currentUser.currentUser.uid);
        setTags(getTagList);
      }
      if (currentUser?.currentUser?.uid) { // ログイン時に fetchTagList を実行
      fetchTagList();
      }
  }, [currentUser]);


    /*
    * タイムスタンプをフォーマットする
    */
    const getFormattedDate = (serverTimestamp) => { // 
      serverTimestamp.year = serverTimestamp.toDate().getFullYear();
      serverTimestamp.month = ("0" + (serverTimestamp.toDate().getMonth() + 1)).slice(-2);
      serverTimestamp.date = ("0" + serverTimestamp.toDate().getDate()).slice(-2);
      return serverTimestamp;
    }
    // yyyy/mm/ddの文字列をDateオブジェクトに変換する関数
    const convertToDate = (dateString) => {
      const [year, month, day] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    };

    /*
    * 未完了TODOの上限チェック
    */
    const isMaxLimitIncompleteTodos = todoItems.length >= 100;  

    const renderTodoForm = () => {
        if (loadingTasks) {
          // データ取得中はスピナーを表示
          return <Spinner />;
        }
        return (
            <>
              <InputTodo
                todoText={todoText}
                onChangeTodoText={onChangeTodoText}
                postTodo={postTodo}
                disabled={isMaxLimitIncompleteTodos}
                dueDate={dueDate}
                setDueDate={setDueDate}
                inputAreaRef={inputAreaRef}
                tags={tags}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
              />
              {isMaxLimitIncompleteTodos && (
                <p className="c-errorMsg">
                  登録できるTODOの上限（５つ）を超えています。
                  TODOを完了してください。
                </p>
              )}
              <InCompleteTodos
                todoItems={todoItems}
                todoDeletedItems={todoDeletedItems}
                toggleComplete={Api.toggleComplete}
                sortCriteria={sortCriteria}
                fetchCompeletedTodos={fetchCompeletedTodos}
                fetchDeletedTodos={fetchDeletedTodos}
                getFormattedDate={getFormattedDate}
                startEditing={startEditing}
                endEditing={endEditing}
                cancelEditing={cancelEditing}
                editingTodoId={editingTodoId}
                newContent={newContent}
                setNewContent={setNewContent}
                loadingTasks={loadingTasks}
                formattedDate={formattedDate}
                setNewDueDate={setNewDueDate}
                newDueDate={newDueDate}
                handleTrashClick={handleTrashClick}
                handleListClick={handleListClick}
                handleDueDateView={handleDueDateView}
                handleTagClick={handleTagClick}
                viewMode={viewMode}
                todoDelete={Api.todoDelete}
                todoTrash={Api.todoTrash}
                todoRestore={Api.todoRestore}
                handleScrollToInputArea={handleScrollToInputArea}
                setSortCriteria={setSortCriteria}
                handleDeleteAll={handleDeleteAll}
                tags={tags}
                sortTagId={sortTagId}
              />
              <CompleteTodos
                todoCompletedItems={todoCompletedItems}
                toggleComplete={Api.toggleComplete}
                sortCriteria={sortCriteria}
                fetchCompeletedTodos={fetchCompeletedTodos}
                getFormattedDate={getFormattedDate}
                loadingTasks={loadingTasks}
                fetchDeletedTodos={fetchDeletedTodos}
              />
              <BottomMenu
              handleScrollToInputArea={handleScrollToInputArea}
              inputAreaRef={inputAreaRef}
              currentUser={currentUser}
              isLoggedIn={isLoggedIn}
              loadingTasks={loadingTasks}
              setTags={setTags}
              tags={tags}
              todoDelete={Api.todoDelete}
              deleteTag={Api.deleteTag}
              handleListClick={handleListClick}
              />
            </>
        );
    }

    return (
        <div>
            {isLoggedIn ? renderTodoForm(): ''}
        </div>
    );
}